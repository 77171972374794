import Axios from "axios";
import Cookies from "js-cookie";

const axios = Axios.create({
    // baseURL: process.env.REACT_APP_STORAGE_URL,
    headers: {
        "Authorization": `Bearer ${Cookies.get("token")}`
    },
    withCredentials: true,
});

const setBearerToken = () => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get("token")}`;
}
setBearerToken();
export default axios;